export const customerFilterLabels = {
  alreadyHaveToys_Accessories: "既に持っているおもちゃの付属品",
  alreadyHaveToys_MakerToyUrl: "既に持っているおもちゃの商品URL",
  alreadyHaveToys_Uuid: "既に持っているおもちゃのUUID",
  alreadyHaveToys_Description: "既に持っているおもちゃの概要",
  alreadyHaveToys_Name: "既に持っているおもちゃの名前",
  alreadyHaveToys_Instruction: "既に持っているおもちゃの使用方法",
  alreadyHaveToys_NoteOfPurchaseForCustomer:
    "既に持っているおもちゃのお客様に対する購入メモ",
  family_Uuid: "家族のUUID",
  familyChildren_FirstName: "子供の名前",
  familyChildren_LastName: "子供の苗字",
  familyChildren_Uuid: "子供のUUID",
  familyChildren_FirstNameOfPronunciation: "子供の名前 (カナ)",
  familyChildren_LastNameOfPronunciation: "子供の苗字 (カナ)",
  familyChildren_NoteFromCustomer: "子供の発育興味",

  familyCustomerAddress_FirstName: "顧客住所の宛名（名前)",
  familyCustomerAddress_LastName: "顧客住所の宛名（苗字)",
  familyCustomerAddress_FirstNameOfPronunciation: "顧客住所の宛名（名前・カナ)",
  familyCustomerAddress_LastNameOfPronunciation: "顧客住所の宛名（苗字・カナ)",
  familyCustomerAddress_Pref: "顧客住所の都道府県",
  familyCustomerAddress_ZipCode: "顧客住所の郵便番号",

  familyCustomerAddresses_FirstName: "顧客住所の宛名（名前)",
  familyCustomerAddresses_LastName: "顧客住所の宛名（苗字)",
  familyCustomerAddresses_FirstNameOfPronunciation:
    "顧客住所の宛名（名前・カナ)",
  familyCustomerAddresses_LastNameOfPronunciation:
    "顧客住所の宛名（苗字・カナ)",
  familyCustomerAddresses_Address1: "顧客住所1",
  familyCustomerAddresses_Address2: "顧客住所2",
  familyCustomerAddresses_Pref: "顧客住所の都道府県",
  familyCustomerAddresses_ZipCode: "顧客住所の郵便番号",
  familyCustomerAddresses_Tel: "顧客住所の電話番号",
  familyCustomerAddresses_Mail: "顧客住所のメールアドレス",
  familyCustomerAddresses_Uuid: "顧客住所のUUID",
  familyCustomerAddresses_IsPrimary: "顧客住所の規定",
  familyCustomerBillingAddresses_FirstName: "顧客の請求先住所の宛名（名前)",
  familyCustomerBillingAddresses_LastName: "顧客の請求先住所の宛名（苗字)",
  familyCustomerBillingAddresses_FirstNameOfPronunciation:
    "顧客の請求先住所の宛名（名前・カナ)",
  familyCustomerBillingAddresses_LastNameOfPronunciation:
    "顧客の請求先住所の宛名（苗字・カナ)",
  familyCustomerBillingAddresses_Address1: "顧客の請求先住所1",
  familyCustomerBillingAddresses_Address2: "顧客の請求先住所2",
  familyCustomerBillingAddresses_Pref: "顧客の請求先住所の都道府県",
  familyCustomerBillingAddresses_ZipCode: "顧客の請求先住所の郵便番号",
  familyCustomerBillingAddresses_Tel: "顧客の請求先住所の電話番号",
  familyCustomerBillingAddresses_Uuid: "顧客の請求先住所のUUID",

  familyCustomerContractedPlans_Uuid: "顧客の契約情報のUUID",
  familyCustomerContractedPlans_AccountToken:
    "顧客の契約情報のアカウントトークン",

  familyCustomerPreSurveys_AlreadyHasToys:
    "顧客のアンケート＿既に持っているおもちゃ",

  familyCustomerPreSurveys_Name: "顧客アンケートの名前",
  familyCustomerPreSurveys_Mail: "顧客アンケートのメールアドレス",
  familyCustomerPreSurveys_Uuid: "顧客アンケートのUUID",
  familyCustomerStatus_Uuid: "顧客の状態のUUID",
  familyCustomerStatus_Name: "顧客の状態",
  familyCustomerType_Uuid: "顧客種別のUUID",
  familyCustomerToyRelations_Uuid: "顧客のおもちゃリレーションのUUID",
  subscription: "契約プラン名",
  subscription_LpCode: "契約プランのLpCode",
  usingToys_Uuid: "利用中のおもちゃのUUID",

  uuid: "UUID",
  requestModificationChannel: "申し込みチャネル",
  cautionScore: "要注意スコア",
  familyCustomerStatus: "顧客の状態",
  familyCustomerType: "顧客種別",
  firstName: "名前",
  firstNameOfPronunciation: "名前 (カナ)",
  lastName: "苗字",
  lastNameOfPronunciation: "苗字（カナ）",
  relationship: "続柄",
  family: "顧客",
  mail: "メールアドレス",
  tel: "電話番号",
  specialNote: "特記事項",
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },
};
