/* eslint-disable react/jsx-props-no-spreading */
import { type NextPage } from "next";
import { Provider as JotaiProvider } from "jotai";
import Head from "next/head";
import nProgress from "nprogress";
import { ThemeProvider } from "@mui/material/styles";
import { type AppProps } from "next/app";
import "normalize.css";
import { useMemo } from "react";
import { Provider } from "react-redux";
import { CacheProvider, type EmotionCache } from "@emotion/react";
import { Router } from "next/router";
import { SWRConfig } from "swr";
import { SnackbarProvider } from "@/components/providers/notistack";
import Main from "@/components/fragments/Main";
import store from "@/modules/store";
import Download from "@/components/fragments/Download";
import "dayjs/locale/ja";
import { isDevelopment, isQa, isStg } from "@/helper/env";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "@/styles/css/main.css";
import "@/styles/css/nprogress.css";
import "@/components/fragments/images-modal/swiper.css";
import { DatadogInit } from "@/lib/datadog";
import "react-quill/dist/quill.snow.css";
import { theme } from "@/styles/mui/theme";
import { createEmotionCache } from "@/lib/emotion";
import { swrConfigValue } from "@/configs/swr";
import "@fontsource/m-plus-1p";
import { GlobalClientErrorBoundary } from "@/components/fragments/errors/error-boundaries/global";
import { LocalStorageProvider } from "@/components/providers/local-storage";

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

nProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => nProgress.start());
Router.events.on("routeChangeComplete", () => nProgress.done());
Router.events.on("routeChangeError", () => nProgress.done());

if (process.env.MSW_ON === "1" && typeof window !== "undefined") {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires -- for ignore production bundle
  require("@/tests/mocks/api/browser");
}

const App: NextPage<AppProps> = (props: MyAppProps) => {
  const { router, emotionCache = clientSideEmotionCache } = props;

  const title = useMemo(() => {
    if (isQa()) {
      return "QA MADRAS";
    }
    if (isStg()) {
      return "STG MADRAS";
    }
    if (isDevelopment()) {
      return "DEV MADRAS";
    }
    return "MADRAS";
  }, []);

  const isDownloadingMode = useMemo(
    () => router.pathname.match(/^\/download\//),
    [router.pathname],
  );

  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>{title}</title>
        </Head>
        <Provider store={store}>
          <JotaiProvider>
            <ThemeProvider theme={theme}>
              <SnackbarProvider>
                <GlobalClientErrorBoundary>
                  <SWRConfig value={swrConfigValue}>
                    <LocalStorageProvider>
                      {isDownloadingMode ? (
                        <Download {...props} ddAllowPrivacy />
                      ) : (
                        <Main {...props} ddAllowPrivacy />
                      )}
                    </LocalStorageProvider>
                  </SWRConfig>
                </GlobalClientErrorBoundary>
              </SnackbarProvider>
            </ThemeProvider>
          </JotaiProvider>
        </Provider>
      </CacheProvider>
      <DatadogInit />
    </>
  );
};

export default App;
