export const TABLE_LABELS = {
  audit_logs: "変更履歴",
  change_cycles: "変更サイクル",
  comment_images: "コメント画像",
  comment_statuses: "コメントステータス",
  comments: "コメント",
  countries: "国",
  coupon_statuses: "クーポンステータス",
  coupons: "クーポン",
  delivery_method_times: "配達指定時間",
  delivery_methods: "発送方法",
  deposits: "入金管理",
  families: "家族",
  /**
   * 使ってない
   */
  family_child_plan_evaluation: "",
  /**
   * 使ってない
   */
  family_child_plan_exchange_toys: "",
  /**
   * 使ってない
   */
  family_child_plan_toy_evaluations: "",
  family_child_plans: "お子様のプラン",
  family_children: "子供",
  family_customer_addresses: "顧客住所",
  family_customer_billing_addresses: "顧客の請求先住所",
  family_customer_contracted_plans: "顧客の契約情報",
  family_customer_coupons: "顧客のクーポン",
  family_customer_detail_survey_labels: "顧客のアンケートラベル",
  family_customer_detail_surveys: "顧客のアンケート",
  family_customer_external_service_types: "顧客の外部サービスタイプ",
  family_customer_external_services: "顧客の外部サービス",
  family_customer_feature_release_types: "顧客のサービスリリースタイプ",
  family_customer_feature_releases: "顧客のサービスリリース",
  family_customer_pre_surveys: "顧客アンケート",
  family_customer_pre_survey_labels: "顧客アンケートラベル",
  family_customer_statuses: "顧客の状態",
  family_customer_toy_images: "顧客のおもちゃの画像",
  family_customer_toy_relations: "顧客のおもちゃリレーション",
  family_customer_types: "顧客種別",
  family_customers: "顧客",
  family_house_types: "建物の種別",
  holidays: "休日",
  incombinable_toy_lists: "組み合わせ不可のおもちゃ一覧",
  incombinable_toy_reasons: "組み合わせ不可のおもちゃの理由",
  incombinable_toys: "組み合わせ不可のおもちゃ",
  /**
   * 使っていない
   */
  individual_toy_defected_attachments: "",
  /**
   * 使っていない
   */
  individual_toy_parts_picks: "",
  individual_toy_status_reasons: "個体のステータスの理由",
  individual_toy_statuses: "個体のステータス",
  /**
   * 使ってない
   */
  individual_toy_suppliers: "個体",
  inventories: "棚卸し",
  inventory_items: "棚卸し商品",
  inventory_staff: "棚卸し担当スタッフ",
  inventory_warehouses: "棚卸し事業所・倉庫",
  legacy_deposits: "レガシーデポジット",
  legacy_shipments: "レガシー発送",
  mail_templates: "メールテンプレート",
  packing_materials: "梱包資材",
  pages: "ページID",
  payment_cycles: "支払いサイクル",
  payment_types: "支払いタイプ",
  plan_template_categories: "プランテンプレートカテゴリー",
  plan_template_category_relations:
    "プランナーメッセージカテゴリーリレーションズ",
  /**
   * 使っていない
   */
  request_modifications: "",
  planner_messages: "プランナーメッセージ",
  required_battery_sizes: "電池のサイズ",
  required_battery_types: "電池の種類",
  safety_borders: "安全基準",
  /**
   * 使っていない
   */
  search_keyword_attributes: "",
  /**
   * 使っていない
   */
  search_keywords: "",
  sent_mails: "送信済みメール",
  shelves: "棚",
  shipment_evaluation: "発送アンケート評価",
  shipment_expiration_date_settings: "出荷期限の設定",
  shipment_statuses: "発送ステータス",
  shipment_toy_customer_receive_statuses: "返却チェック(顧客)",
  shipment_toy_status_reasons: "返却チェックの理由",
  shipment_toy_statuses: "返却チェック",
  shipment_toys: "発送おもちゃ",
  shipment_types: "発送の種類",
  shipments: "発送",
  staff: "スタッフ",
  staff_access_keys: "スタッフアクセスキー",
  staff_allow_domains: "スタッフの許可されたドメイン",
  staff_backlog_keys: "スタッフのbacklogキー",
  stock_toys: "在庫おもちゃ",
  stocks: "在庫",
  /**
   * 使ってない
   */
  subscription_payment_type_relations: "",
  subscription_statuses: "料金コースのステータス",
  subscriptions: "料金コース",
  toy_attachments: "おもちゃのアタッチメント",
  toy_categories: "おもちゃのカテゴリー",
  toy_category_relations: "おもちゃのカテゴリーリレーション",
  toy_cautions: "おもちゃの注意事項",
  toy_cleaning_images: "清掃・梱包方法画像",
  toy_images: "おもちゃの画像",
  toy_instructions_attachments: "おもちゃの説明書アタッチメント",
  toy_makers: "おもちゃのメーカー",
  toy_material_relations: "おもちゃの材質のリレーション",
  toy_materials: "おもちゃの材質",
  toy_cleaning_difficulty_rate: "清掃難易度",
  /**
   * 使っていない
   */
  toy_notes: "",
  toy_parts: "おもちゃのパーツ",
  toy_parts_images: "おもちゃのパーツ画像",
  toy_produce_statuses: "おもちゃの生産状況",
  /**
   * 使っていない
   */
  toy_provision_areas: "",
  toy_required_batteries: "おもちゃに必要な電池",
  toy_safety_border_relations: "おもちゃの暗線基準のリレーション",
  toy_statuses: "おもちゃの取り扱いステータス",
  toy_supplier_relations: "おもちゃの仕入先企業のリレーション",
  toy_supplier_staff: "仕入先企業の担当者",
  toy_suppliers: "仕入先企業",
  toy_toy_caution_relations: "おもちゃとおもちゃの注意事項のリレーション",
  toys: "おもちゃ",
  unavailable_toy_example_images: "非運用判定基準画像",
  warehouse_transfer_individual_toys: "移動対象の個体のおもちゃ",
  warehouse_transfer_staff: "移動対象の実地スタッフ",
  warehouse_transfers: "事業所間移動管理",
  warehouse_types: "事業所・倉庫の種別",
  warehouses: "倉庫",
} as const;

type KEYS_TABLE_LABELS = keyof typeof TABLE_LABELS;

export type TABLE_LABELS = Exclude<
  KEYS_TABLE_LABELS,
  | "family_child_plan_evaluation"
  | "family_child_plan_exchange_toys"
  | "family_child_plan_toy_evaluations"
  | "individual_toy_defected_attachments"
  | "individual_toy_parts_picks"
  | "individual_toy_suppliers"
  | "request_modifications"
  | "search_keyword_attributes"
  | "search_keywords"
  | "subscription_payment_type_relations"
  | "toy_notes"
  | "toy_provision_areas"
>;
